<template>
  <div class="emb-contact-wrap">
    <div class="d-flex justify-center text-h4 mx-6 mt-6 pt-3 font-weight-bold">
      {{ $t("message.faqPage.title") }}
    </div>
    <v-container grid-list-xl>
      <v-card v-if="panelIndex == undefined" class="my-6">
        <v-layout row wrap class="ma-6">
          <v-flex sm12 md12 lg5 xl5>
            <div class="sec-title">
              <div class="text-subtitle-1">
                {{ $t("message.contactPage.desc") }}
              </div>
            </div>
            <!-- {{contactInfo.info}} -->
          </v-flex>
          <v-flex sm12 md12 lg7 xl7>
            <div class="sec-title">
              <div class="text-h5">
                <v-icon class="pr-2">mdi-message</v-icon
                >{{ $t("message.contactPage.chatTitle") }}
              </div>
              <div class="text-subtitle-1 mt-2">
                {{ $t("message.contactPage.chatDesc") }}
              </div>
            </div>
          </v-flex>
          <v-flex sm12 md12 lg7 xl7>
            <div class="sec-title">
              <div class="text-h5 mb-2">
                <v-icon>mdi-at</v-icon>{{ $t("message.contactPage.writeToUs") }}
              </div>
            </div>
            <form @submit.prevent="sendEmail">
              <label>{{ $t("message.contactPage.name") }}</label>
              <input
                type="text"
                v-model="name"
                name="name"
                :placeholder="$t('message.contactPage.nameField')"
              />
              <label>{{ $t("message.contactPage.email") }}</label>
              <input
                type="email"
                v-model="email"
                name="email"
                :placeholder="$t('message.contactPage.emailField')"
              />
              <label>{{ $t("message.contactPage.message") }}</label>
              <textarea
                name="message"
                v-model="message"
                cols="30"
                rows="5"
                :placeholder="$t('message.contactPage.messageField')"
              >
              </textarea>
              <v-banner
                class="primary white--text mb-3 font-weight-black"
                v-if="order_in_process"
              >
                {{ $t("message.contactPage.orderInProcessWarning") }}
              </v-banner>
              <input
                type="submit"
                :value="$t('message.contactPage.sendBtn')"
                class="accent"
              />
            </form>
          </v-flex>
        </v-layout>
      </v-card>
    </v-container>
  </div>
</template>

<style scoped>
label {
  float: left;
}

input[type="text"],
[type="email"],
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #45a049;
}
</style>
<script>
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  limit,
} from "firebase/firestore";
import api from "Api";
import { mapGetters } from "vuex";
import emailjs from "emailjs-com";

export default {
  computed: {
    ...mapGetters(["getUser"]),
  },
  data() {
    return {
      order_in_process: false,
      name: "",
      email: "",
      message: "",
      valid: false,
      contactInfo: "",
      emailRules: [
        (v) => !!v || this.$t("message.contactPage.validation.emailRequired"),
        (v) =>
          /.+@.+/.test(v) ||
          this.$t("message.contactPage.validation.emailValid"),
      ],
      inputRules: {
        basictextRules: [
          (v) => !!v || this.$t("message.contactPage.validation.notEmpty"),
        ],
      },
    };
  },
  mounted() {
    this.getContactInfo();
    if (this.getUser != undefined) {
      this.fetch_user_orders();
    }
  },
  watch: {
    "$store.state.user": function () {
      if (this.getUser != undefined) {
        this.fetch_user_orders();
      }
    },
  },
  methods: {
    getContactInfo() {
      api
        .get("contact.json")
        .then((response) => {
          this.contactInfo = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveDetails() {
      this.$refs.form.validate();
    },
    sendEmail(e) {
      try {
        var info = {
          name: this.name,
          email: this.email,
          message: this.message,
        };
        console.log("user: ", this.getUser);
        if (this.getUser != null) {
          info["uid"] = this.getUser.uid;
        }
        emailjs
          .sendForm(
            "service_uef5iqj",
            "template_a8lam7p",
            e.target,
            "user_oegGqOYRU9oMh2SCr1td8",
            info
          )
          .then(
            (result) => {
              console.log("SUCCESS!", result.status, result.text);
              this.$snotify.success(this.$t("message.contactPage.emailSent"), {
                closeOnClick: false,
                pauseOnHover: false,
                timeout: 2000,
              });
            },
            (error) => {
              console.log("FAILED...", error);
            }
          );
      } catch (error) {
        console.log({ error });
      }
      window.scrollTo(0, 0);
      // Reset form field
      this.name = "";
      this.email = "";
      this.message = "";
    },
    fetch_user_orders() {
      const ordersCollectionRef = collection(getFirestore(), "orders");
      const queryRef = query(
        ordersCollectionRef,
        where("client_id", "==", this.getUser.uid),
        where("general_status", "==", "IN_PROCESS"),
        limit(1)
      );

      getDocs(queryRef).then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          this.order_in_process = true;
        }
      });
    },
  },
};
</script>

